var render = function () {
  var _vm$functions$getFilt, _vm$functions$getFilt2, _vm$functions$getFilt3, _vm$functions$getFilt4, _vm$functions$getFilt5, _vm$functions$getFilt6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4 white rounded-lg elevation-0"
  }, [_c('v-row', {
    staticClass: "px-4 pt-7"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonName).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "full-width": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonName).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonName), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.PersonName).value"
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).items.value,
      "item-text": "id",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": (_vm$functions$getFilt = _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).isLoading) === null || _vm$functions$getFilt === void 0 ? void 0 : _vm$functions$getFilt.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.PersonIds).value"
    }
  })], 1), _c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": (_vm$functions$getFilt2 = _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).isLoading) === null || _vm$functions$getFilt2 === void 0 ? void 0 : _vm$functions$getFilt2.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).label,
      "show-with-children": _vm.state.profUnitWithChildren,
      "children-toggle": ""
    },
    on: {
      "toggleWithChildren": function toggleWithChildren($event) {
        return _vm.listeners.onToggleProfUnitWithChildren();
      }
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.state.profUnitWithChildren ? _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren : _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.state.profUnitWithChildren ? _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren : _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds), "value", $$v);
      },
      expression: "\n          functions.getFilterByKey(\n            state.profUnitWithChildren\n              ? constants.FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren\n              : constants.FILTER_FIELD_KEY.ProfessionalUnitIds\n          ).value\n        "
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.OrganizationalUnitIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": (_vm$functions$getFilt3 = _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.OrganizationalUnitIds).isLoading) === null || _vm$functions$getFilt3 === void 0 ? void 0 : _vm$functions$getFilt3.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.OrganizationalUnitIds).label,
      "show-with-children": _vm.state.orgUnitWithChildren,
      "children-toggle": ""
    },
    on: {
      "toggleWithChildren": function toggleWithChildren($event) {
        return _vm.listeners.onToggleOrgUnitWithChildren();
      }
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.state.orgUnitWithChildren ? _vm.constants.FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren : _vm.constants.FILTER_FIELD_KEY.OrganizationalUnitIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.state.orgUnitWithChildren ? _vm.constants.FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren : _vm.constants.FILTER_FIELD_KEY.OrganizationalUnitIds), "value", $$v);
      },
      expression: "\n          functions.getFilterByKey(\n            state.orgUnitWithChildren\n              ? constants.FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren\n              : constants.FILTER_FIELD_KEY.OrganizationalUnitIds\n          ).value\n        "
    }
  })], 1), _c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": (_vm$functions$getFilt4 = _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).isLoading) === null || _vm$functions$getFilt4 === void 0 ? void 0 : _vm$functions$getFilt4.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfileIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ProfileIds).value"
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SpecialRoleIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": (_vm$functions$getFilt5 = _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SpecialRoleIds).isLoading) === null || _vm$functions$getFilt5 === void 0 ? void 0 : _vm$functions$getFilt5.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SpecialRoleIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SpecialRoleIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SpecialRoleIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.SpecialRoleIds).value"
    }
  })], 1), _c('v-col', [_c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractEndFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractEndFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractEndFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ContractEndFrom).value"
    }
  }), _c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractStartTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractStartTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractStartTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ContractStartTo).value"
    }
  })], 1), _c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationships).items.value,
      "item-text": "name",
      "item-value": "name",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationships).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationships).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationships), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ContractualRelationships).value"
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SupplierIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": (_vm$functions$getFilt6 = _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SupplierIds).isLoading) === null || _vm$functions$getFilt6 === void 0 ? void 0 : _vm$functions$getFilt6.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SupplierIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SupplierIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SupplierIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.SupplierIds).value"
    }
  })], 1), _c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Position).items.value,
      "item-text": "name",
      "item-value": "value",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "multiple": false,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Position).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Position).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Position), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Position).value"
    }
  }), _c('CommonSwitchFilter', {
    staticClass: "ml-2 pt-0 mt-n2",
    attrs: {
      "value": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Active).value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Active).label,
      "inset": "",
      "with-date": true
    },
    on: {
      "change": function change($event) {
        _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Active).value = $event;
      }
    }
  }), _c('CommonSwitchFilter', {
    staticClass: "ml-2 pt-0 mt-n2",
    attrs: {
      "value": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.IncludeAnonymized).value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.IncludeAnonymized).label,
      "inset": ""
    },
    on: {
      "change": function change($event) {
        _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.IncludeAnonymized).value = $event;
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }